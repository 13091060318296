<template>
  <div class="row">
    <div class="col-12">
      <b-form-group label="Categoria" label-for="categoria">
        <v-select placeholder="Seleccione una categoria" v-model="form.category_id" label="name" :options="categories" :reduce="item => item.id" :clearable="false" >
          <template #search="{attributes, events}">
            <input
                class="vs__search"
                :required="!form.category_id"
                v-bind="attributes"
                v-on="events"
            />
          </template>
        </v-select>
      </b-form-group>
      <b-form-group label="Nombre" label-for="name">
        <b-form-input id="name" v-model="form.name" placeholder="Ingrese un nombre" :required="true" />
      </b-form-group>
      <b-form-group label="Estatus" label-for="status">
        <input-switch v-model="form.verified"></input-switch>
      </b-form-group>
      <b-form-group>
        <div class="d-flex justify-content-end">
          <button-store />
        </div>
      </b-form-group>
    </div>
  </div>
</template>


<script>
import {
  BFormGroup, BFormInput, BFormCheckbox,
} from 'bootstrap-vue'
import InputSwitch from "@/components/InputSwitch";
import ButtonStore from "@/components/ButtonStore";
import vSelect from 'vue-select'
import CategoryService from "@/services/CategoryService";

export default {
  components: {
    ButtonStore,
    InputSwitch,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    vSelect
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: this.value,
      categories : []
    }
  },
  created() {
    this.getCategories()
  },
  methods:{
    async getCategories() {
      const response = await CategoryService.get();
      this.categories = response.data.data.item;
    }
  },
  watch: {
    form: {
      handler(val) {
        this.$emit('input', val)
      },
      deep: true,
    },
    value(value) {
      this.form = value
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
