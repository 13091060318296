import axiosIns from "@/libs/axios";

export default class ServiceService {
    static get() {
        return axiosIns.get('/services')
    }

    static store(data){
        return axiosIns.post('/services', data)
    }

    static update(data){
        return axiosIns.post('/services/verified-single', data)
    }
}
